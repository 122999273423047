import '../css/marketing/site.scss'

$(document).ready(() => { 
  fetch("https://public-api.wordpress.com/rest/v1.1/sites/blog.themainstage.com/posts")
    .then(r => r.json())
    .then(data => {
      data.posts.forEach((post, index) => { 
        if ( index < 4 ) { 
          let div = $($("#blog-template").html())
          div.find("img").attr("src", post.featured_image)
          div.find('h5').html(post.title)
          $('.blog-inner').append(div)
          div.click(() => window.location = post.URL)
        }
      })
      //$('#blog-carousel').carousel({ interval: 3000 })
    })
})
